<!--  -->
<template>
<div class=''>
<div>
        <div class="Nynewscenter">
            <div class="hotnews-module">
            <div class="commonweb ">
                <div class="hotnewsTop">
                    <ul class="swiper-wrapper clearfix">


                        <li class="swiper-slide">
                            <dl class="hotnewslist-dl clearfix">
                                <dt><a href="81.html"><img :src="require('./../../../public/page1/20190221021550_514524.jpg')" /></a></dt>
                                <dd>
                                    <h4><a href="81.html">未来三年股东回报规划（2017年-2019年）</a>
                                    </h4>
                                    <p class="hotnewstext">深圳市沃尔核材股份有限公司<br />未来三年股东回报规划（2017年-2019年）<br /></p>
                                    <a class="hotnewslink" href="81.html">了解详细</a>
                                </dd>
                            </dl>
                        </li>


                        <li class="swiper-slide">
                            <dl class="hotnewslist-dl clearfix">
                                <dt><a href="79.html"><img :src="require('./../../../public/page1/20190221021607_744510.jpg')" /></a></dt>
                                <dd>
                                    <h4><a href="79.html">什么是上市公司商誉减值风险</a>
                                    </h4>
                                    <p class="hotnewstext"></p>
                                    <a class="hotnewslink" href="79.html">了解详细</a>
                                </dd>
                            </dl>
                        </li>


                        <li class="swiper-slide">
                            <dl class="hotnewslist-dl clearfix">
                                <dt><a href="35.html"><img :src="require('./../../../public/page1/wqimage15.jpg')" /></a></dt>
                                <dd>
                                    <h4><a href="35.html">关注上市公司现金分红的持续性和稳定性2</a>
                                    </h4>
                                    <p class="hotnewstext">
                                        近年来深市上市公司在现金分红家数占比、金额和分红连续性等方面不断向好，市场中涌现出一批坚持长期现金分红的公司，这些公司实行积极近年来深市上市公司在现金分红家数占比、金额和分红连续性等方面不断向好近年来深市上市公司在现金分红家数占比、金额和分红连续性等方面不断向好，市场中涌现出一批坚持长期现金分红的公司，这些公司实行积极近年来深市上市公司在现金分红家数占比、金额和分红连续性等方面不断向好
                                    </p>
                                    <a class="hotnewslink" href="35.html">了解详细</a>
                                </dd>
                            </dl>
                        </li>

                    </ul>
                    <div class="banner-shotnewsbtn"></div>

                </div>
            </div>
        </div>
    </div>
    <!-- 卡牌反转 -->
        <div class="newslist-module">
            <div class="commonweb">
                <ul class="clearfix data-page-cont" id="list">
                    <!--图片尺寸378*213-->


                    <li class="col-lg-4 col-md-4 col-sm-6">
                        <div class="newslistcont">
                            <div class="newslistext front">
                                <h4><a href="#">未来三年股东回报规划（2017年-2019年）</a>
                                </h4>
                                <p class="nstext-p">深圳市沃尔核材股份有限公司<br />未来三年股东回报规划（2017年-2019年）<br /></p>
                                <div class="newsdate-time">
                                    <p>21</p>
                                    <span>2019-02</span>
                                </div>
                            </div>
                            <div class="newslisimg back">
                                <a class="nsimg-img" href="81.html">
                                    <img :src="require('./../../../public/page1/20190221021357_393054.jpg')">
                                </a>
                                <h4><a href="81.html">未来三年股东回报规划（2017年-2019年）</a>
                                </h4>
                            </div>
                        </div>
                    </li>

                    <li class="col-lg-4 col-md-4 col-sm-6">
                        <div class="newslistcont">
                            <div class="newslistext front">
                                <h4><a href="80.html">证监会投资者保护局负责人答记者问</a>
                                </h4>
                                <p class="nstext-p"></p>
                                <div class="newsdate-time">
                                    <p>21</p>
                                    <span>2019-02</span>
                                </div>
                            </div>
                            <div class="newslisimg back">
                                <a class="nsimg-img" href="80.html">
                                    <img :src="require('./../../../public/page1/20190221021357_393054.jpg')">
                                </a>
                                <h4><a href="80.html">证监会投资者保护局负责人答记者问</a>
                                </h4>
                            </div>
                        </div>
                    </li>

                    <li class="col-lg-4 col-md-4 col-sm-6">
                        <div class="newslistcont">
                            <div class="newslistext front">
                                <h4><a href="#">什么是上市公司商誉减值风险</a>
                                </h4>
                                <p class="nstext-p"></p>
                                <div class="newsdate-time">
                                    <p>21</p>
                                    <span>2019-02</span>
                                </div>
                            </div>
                            <div class="newslisimg back">
                                <a class="nsimg-img" href="#">
                                    <img :src="require('./../../../public/page1/20190221021426_658728.jpg')">
                                </a>
                                <h4><a href="#">什么是上市公司商誉减值风险</a>
                                </h4>
                            </div>
                        </div>
                    </li>

                    <li class="col-lg-4 col-md-4 col-sm-6">
                        <div class="newslistcont">
                            <div class="newslistext front">
                                <h4><a href="#">关注上市公司现金分红的持续性和稳定性</a>
                                </h4>
                                <p class="nstext-p">
                                    近年来深市上市公司在现金分红家数占比、金额和分红连续性等方面不断向好，市场中涌现出一批坚持长期现金分红的公司，这些公司实行积极近年来深市上市公司在现金分红家数占比、金额和分红连续性等方面不断向好近年来深市上市公司在现金分红家数占比、金额和分红连续性等方面不断向好，市场中涌现出一批坚持长期现金分红的公司，这些公司实行积极近年来深市上市公司在现金分红家数占比、金额和分红连续性等方面不断向好
                                </p>
                                <div class="newsdate-time">
                                    <p>13</p>
                                    <span>2018-12</span>
                                </div>
                            </div>
                            <div class="newslisimg back">
                                <a class="nsimg-img" href="#">
                                    <img :src="require('./../../../public/page1/images01.jpg')">
                                </a>
                                <h4><a href="#">关注上市公司现金分红的持续性和稳定性</a>
                                </h4>
                            </div>
                        </div>
                    </li>

                    <li class="col-lg-4 col-md-4 col-sm-6">
                        <div class="newslistcont">
                            <div class="newslistext front">
                                <h4><a href="#">关注上市公司现金分红的持续性和稳定性</a>
                                </h4>
                                <p class="nstext-p">
                                    近年来深市上市公司在现金分红家数占比、金额和分红连续性等方面不断向好，市场中涌现出一批坚持长期现金分红的公司，这些公司实行积极近年来深市上市公司在现金分红家数占比、金额和分红连续性等方面不断向好近年来深市上市公司在现金分红家数占比、金额和分红连续性等方面不断向好，市场中涌现出一批坚持长期现金分红的公司，这些公司实行积极近年来深市上市公司在现金分红家数占比、金额和分红连续性等方面不断向好
                                </p>
                                <div class="newsdate-time">
                                    <p>13</p>
                                    <span>2018-12</span>
                                </div>
                            </div>
                            <div class="newslisimg back">
                                <a class="nsimg-img" href="#">
                                    <img :src="require('./../../../public/page1/images01.jpg')">
                                </a>
                                <h4><a href="#">关注上市公司现金分红的持续性和稳定性</a>
                                </h4>
                            </div>
                        </div>
                    </li>

                    <li class="col-lg-4 col-md-4 col-sm-6">
                        <div class="newslistcont">
                            <div class="newslistext front">
                                <h4><a href="#">关注上市公司现金分红的持续性和稳定性</a>
                                </h4>
                                <p class="nstext-p">
                                    近年来深市上市公司在现金分红家数占比、金额和分红连续性等方面不断向好，市场中涌现出一批坚持长期现金分红的公司，这些公司实行积极近年来深市上市公司在现金分红家数占比、金额和分红连续性等方面不断向好近年来深市上市公司在现金分红家数占比、金额和分红连续性等方面不断向好，市场中涌现出一批坚持长期现金分红的公司，这些公司实行积极近年来深市上市公司在现金分红家数占比、金额和分红连续性等方面不断向好
                                </p>
                                <div class="newsdate-time">
                                    <p>13</p>
                                    <span>2018-12</span>
                                </div>
                            </div>
                            <div class="newslisimg back">
                                <a class="nsimg-img" href="#">
                                    <img :src="require('./../../../public/page1/images01.jpg')">
                                </a>
                                <h4><a href="#">关注上市公司现金分红的持续性和稳定性</a>
                                </h4>
                            </div>
                        </div>
                    </li>

                    <li class="col-lg-4 col-md-4 col-sm-6">
                        <div class="newslistcont">
                            <div class="newslistext front">
                                <h4><a href="#">关注上市公司现金分红的持续性和稳定性</a>
                                </h4>
                                <p class="nstext-p">
                                    近年来深市上市公司在现金分红家数占比、金额和分红连续性等方面不断向好，市场中涌现出一批坚持长期现金分红的公司，这些公司实行积极近年来深市上市公司在现金分红家数占比、金额和分红连续性等方面不断向好近年来深市上市公司在现金分红家数占比、金额和分红连续性等方面不断向好，市场中涌现出一批坚持长期现金分红的公司，这些公司实行积极近年来深市上市公司在现金分红家数占比、金额和分红连续性等方面不断向好
                                </p>
                                <div class="newsdate-time">
                                    <p>13</p>
                                    <span>2018-12</span>
                                </div>
                            </div>
                            <div class="newslisimg back">
                                <a class="nsimg-img" href="#">
                                    <img :src="require('./../../../public/page1/images01.jpg')">
                                </a>
                                <h4><a href="#">关注上市公司现金分红的持续性和稳定性</a>
                                </h4>
                            </div>
                        </div>
                    </li>

                    <li class="col-lg-4 col-md-4 col-sm-6">
                        <div class="newslistcont">
                            <div class="newslistext front">
                                <h4><a href="#">关注上市公司现金分红的持续性和稳定性</a>
                                </h4>
                                <p class="nstext-p">
                                    近年来深市上市公司在现金分红家数占比、金额和分红连续性等方面不断向好，市场中涌现出一批坚持长期现金分红的公司，这些公司实行积极近年来深市上市公司在现金分红家数占比、金额和分红连续性等方面不断向好近年来深市上市公司在现金分红家数占比、金额和分红连续性等方面不断向好，市场中涌现出一批坚持长期现金分红的公司，这些公司实行积极近年来深市上市公司在现金分红家数占比、金额和分红连续性等方面不断向好
                                </p>
                                <div class="newsdate-time">
                                    <p>13</p>
                                    <span>2018-12</span>
                                </div>
                            </div>
                            <div class="newslisimg back">
                                <a class="nsimg-img" href="#">
                                    <img :src="require('./../../../public/page1/images01.jpg')">
                                </a>
                                <h4><a href="#">关注上市公司现金分红的持续性和稳定性</a>
                                </h4>
                            </div>
                        </div>
                    </li>

                    <li class="col-lg-4 col-md-4 col-sm-6">
                        <div class="newslistcont">
                            <div class="newslistext front">
                                <h4><a href="#">关注上市公司现金分红的持续性和稳定性</a>
                                </h4>
                                <p class="nstext-p">
                                    近年来深市上市公司在现金分红家数占比、金额和分红连续性等方面不断向好，市场中涌现出一批坚持长期现金分红的公司，这些公司实行积极近年来深市上市公司在现金分红家数占比、金额和分红连续性等方面不断向好近年来深市上市公司在现金分红家数占比、金额和分红连续性等方面不断向好，市场中涌现出一批坚持长期现金分红的公司，这些公司实行积极近年来深市上市公司在现金分红家数占比、金额和分红连续性等方面不断向好
                                </p>
                                <div class="newsdate-time">
                                    <p>13</p>
                                    <span>2018-12</span>
                                </div>
                            </div>
                            <div class="newslisimg back">
                                <a class="nsimg-img" href="#">
                                    <img :src="require('./../../../public/page1/images01.jpg')">
                                </a>
                                <h4><a href="#">关注上市公司现金分红的持续性和稳定性</a>
                                </h4>
                            </div>
                        </div>
                    </li>

                    <li class="col-lg-4 col-md-4 col-sm-6">
                        <div class="newslistcont">
                            <div class="newslistext front">
                                <h4><a href="#">关注上市公司现金分红的持续性和稳定性8</a>
                                </h4>
                                <p class="nstext-p">
                                    近年来深市上市公司在现金分红家数占比、金额和分红连续性等方面不断向好，市场中涌现出一批坚持长期现金分红的公司，这些公司实行积极近年来深市上市公司在现金分红家数占比、金额和分红连续性等方面不断向好近年来深市上市公司在现金分红家数占比、金额和分红连续性等方面不断向好，市场中涌现出一批坚持长期现金分红的公司，这些公司实行积极近年来深市上市公司在现金分红家数占比、金额和分红连续性等方面不断向好
                                </p>
                                <div class="newsdate-time">
                                    <p>13</p>
                                    <span>2018-12</span>
                                </div>
                            </div>
                            <div class="newslisimg back">
                                <a class="nsimg-img" href="#">
                                    <img :src="require('./../../../public/page1/images01.jpg')">
                                </a>
                                <h4><a href="#">关注上市公司现金分红的持续性和稳定性8</a>
                                </h4>
                            </div>
                        </div>
                    </li>

                </ul>
                <a href="#" class="loadingmore news-loadingmore" style="color:#fff">浏览更多</a>
            </div>
        </div>
</div>

</div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
//import引入的组件需要注入到对象中才能使用
components: {},
data() {
//这里存放数据
return {

};
},
//监听属性 类似于data概念
computed: {},
//监控data中的数据变化
watch: {},
//方法集合
methods: {

},
//生命周期 - 创建完成（可以访问当前this实例）
created() {

},
//生命周期 - 挂载完成（可以访问DOM元素）
mounted() {

},
beforeCreate() {}, //生命周期 - 创建之前
beforeMount() {}, //生命周期 - 挂载之前
beforeUpdate() {}, //生命周期 - 更新之前
updated() {}, //生命周期 - 更新之后
beforeDestroy() {}, //生命周期 - 销毁之前
destroyed() {}, //生命周期 - 销毁完成
activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.hotnewslist-dl dd h4{
    text-align: left;
}
.hotnewstext{
    text-align: left;
}
.swiper-slide.swiper-slide-duplicate.swiper-slide-duplicate-prev
h4,.nstext-p{
    text-align: left;
}
.swiper-slide{
    margin-bottom: 0;
}
</style>